<template>
  <div class="city_box">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">选择城市</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <van-search
        v-model="cityValue"
        shape="round"
        left-icon=""
        background="#ffffff"
        placeholder="请输入您需要搜索的地址"
        @input="input"
      ></van-search>
      <!-- <div class="currentCity">
        <van-icon name="location-o"></van-icon>
        <p>当前城市</p>
        <span style="color: rgb(242, 163, 47)">{{ currentLocation }}</span>
      </div> -->
      <div class="hot-city">
        <div class="hot-city-title">
          <!-- <van-icon name="fire" color="red"></van-icon> -->
          <span>热门地区推荐</span>
          <!---->
        </div>
        <div class="hot-city-list">
          <div class="city" v-for="(item, index) in hotestCity" :key="index" @click="toLocation(item)">
            {{ item }}
          </div>
        </div>
      </div>
      <!-- 遮挡层
      下面城市列表中的 letter 层，会在苹果手机浏览器里面漏出一点点内容，
      需要这div进行一个遮挡
      -->
      <div class="occlude"></div>
      <!-- 城市列表 -->
      <div class="city-list-box">
        <div class="city-list" v-for="(item, index) in provinces" :key="index">
          <div class="letter" :id="item.letter">{{ item.letter }}</div>
          <ul>
            <li class="city" :class="province.city" v-for="(province, index) in item.provinces" :key="index" @click="toLocation(province.city)">
              {{ province.city }}
            </li>
          </ul>
        </div>
      </div>
      <!-- 城市字母列表 -->
      <div class="letter-box">
        <p
          v-for="(letter, index) in letters"
          :key="index"
          @click="toLetterLocation(letter)"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
        >
          {{ letter }}
        </p>
      </div>
      <div class="active-letter" v-if="isLetter">
        <span>{{ activeLetter }}</span>
      </div>
    </div>
    <van-overlay :show="loading" z-index="101">
      <div class="wrapper" @click.stop>
        <img class="loading" slot="loading" src="@/assets/gif/loading.gif" rel="external nofollow" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import citysJson from './citys.json';
import { pinyin } from 'pinyin-pro';
import { Search, Toast } from 'vant';
import { Icon } from 'vant';
import { Overlay } from 'vant';
import { setLocalItem } from '@/utils/longStorage';
import { modifyPersonInfo } from '@/api/user';

export default {
  name: 'location',
  components: {
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Overlay.name]: Overlay,
  },
  data() {
    return {
      cityValue: '',
      currentLocation: '未知',
      hotestCity: ['成都', '北京', '上海', '杭州', '广州', '深圳', '南京', '重庆', '长沙', '苏州'],
      provinces: [], // 省份
      provincesCopy: [], // 省份副本
      loading: false, // 遮罩层加载数据
      letter: '',
      city: '',
      letters: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'J',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        '#',
      ], // 字母
      letterHeight: [], // 字母的高度
      startY: '', // 字母列表距离顶部的高度
      activeLetter: 'A', // 激活时的字母
      isLetter: false, // 是否显示字母气泡
      timer: null,
    };
  },
  async created() {
    let req = {
      hotestNum: 9,
    };
    this.getLocation();
    this.getLoufengCityList(citysJson.citys);
  },
  methods: {
    leterHeight() {
      this.$nextTick(() => {
        this.letterHeight = this.letters.map((item) => {
          let dom = document.getElementById(item) ? document.getElementById(item) : null;
          if (dom) {
            let height = document.getElementById(item).offsetTop;
            let obj = {
              letter: item,
              height: height,
            };
            return obj;
          }
        });
        this.startY = document.getElementsByClassName('letter-box')[0].offsetTop;
      });
    },
    handleTouchStart() {
      this.isLetter = true;
    },
    handleTouchMove(e) {
      const touchY = e.touches[0].clientY || e.touches[0].pageY;
      let index = Math.floor((touchY - this.startY) / 20); // 20 为字母的高度，通过此计算出字母的下标值
      if (index > this.letters.length - 1) {
        index = this.letters.length - 1;
      } else if (index < 0) {
        index = 0;
      }
      this.activeLetter = this.letters[index];
      this.toLetterLocation(this.activeLetter, 'noTimeNeeded');
    },
    handleTouchEnd() {
      this.isLetter = false;
    },
    // 选择当前城市
    input() {
      if (this.timer != null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        let filterList = citysJson.citys.filter((cityName, index) => {
          let fIndex = cityName.indexOf(this.cityValue);
          return fIndex > -1;
        });
        this.getLoufengCityList(filterList);
      }, 333);
      // if (this.cityValue) {
      //   let dom = document.getElementsByClassName(this.cityValue)[0];
      //   let domC = document.getElementsByClassName('city-list-box')[0];
      //   if (domC.scrollTop) {
      //     domC.scrollTop = dom.offsetTop - domC.offsetTop - 50;
      //     Toast(`已找到该城市`);
      //   } else {
      //     Toast(`未找到该城市`);
      //   }
      // }
    },
    // 跳转字母对应的城市
    toLetterLocation(letter, param) {
      this.isLetter = true;
      if (param !== 'noTimeNeeded') {
        setTimeout(() => {
          this.isLetter = false;
        }, 1000);
      }
      this.activeLetter = letter; // 更改气泡的字母
      let letterHeight = [];
      this.letterHeight.forEach((item) => {
        if (item?.letter === letter) {
          letterHeight = item.height;
        }
      });
      let dom = document.getElementsByClassName('city-list-box')[0];
      dom.scrollTop = letterHeight - dom.offsetTop + 2;
    },
    // 获取当前位置信息
    getLocation() {
      this.currentLocation = localStorage.getItem('location');
      if (!this.currentLocation) {
        this.currentLocation = '未知';
      }
    },
    async getLoufengCityList(citys) {
      let tempArr = this.extractFirstLetter(citys);
      // 按字母对城市列表进行排序
      tempArr.sort((a, b) => {
        return a.letter.localeCompare(b.letter);
      });
      this.provincesCopy = tempArr;
      let tempObj = this.provincesSort(tempArr);
      this.provinces = this.inductiveCity(tempObj.letterArr);
      this.provinces = this.provinces.concat([
        {
          letter: '#',
          provinces: tempObj.noLetterArr,
        },
      ]);
      this.letters = this.provinces.map((item) => item.letter);
      this.leterHeight();
    },
    // 将每个对象中的省份，提取首字母，并存回去
    extractFirstLetter(list) {
      let tempArr = list.map((item) => {
        let first = pinyin(item.substring(0, 1), {
          pattern: 'first',
          toneType: 'none',
        }).toUpperCase();
        let obj = {};
        obj.letter = first;
        obj.city = item;
        return obj;
      });
      return tempArr;
    },
    // 将一个数组里的对象，是字母的放在一个数据，非数组放在另一个数组
    provincesSort(provinces) {
      let obj = {};
      let letterArr = [];
      let noLetterArr = [];
      let pattern = new RegExp('[A-Z]');
      provinces.forEach((item) => {
        if (pattern.test(item.letter)) {
          letterArr.push(item);
        } else {
          noLetterArr.push(item);
        }
      });
      obj.letterArr = letterArr;
      obj.noLetterArr = noLetterArr;
      return obj;
    },
    /**
     * 业务：数组对象中，将相同的某个属性的对象归纳在一个数组中，并且将相同的属性
     * 提取出来，存放在对象中，最后将对象存在数组中；
     * @param ArrayList
     * [
     *  {
     *    attr: ''
     *  },
     *  {
     *    attr: ''
     *   }
     * ]
     * @return ArrayList
     * [{
     *    attr: '',
     *    obj: {
     *     attr:'',
     *     attr2: []
     *    }
     * }]
     */
    inductiveCity(province) {
      let obj = {},
        newArr = [];
      province.forEach(function (item) {
        if (!obj[item.letter]) {
          var tempObj = {
            letter: '',
            provinces: [],
          };
          tempObj.letter = item.letter;
          var tempArr = [];
          tempArr.push(item);
          tempObj.provinces = tempArr;
          newArr.push(tempObj);
          obj[item.letter] = item;
        } else {
          newArr.forEach(function (value) {
            if (value.provinces[0].letter == item.letter) {
              value.provinces.push(item);
            }
          });
        }
      });
      return newArr;
    },
    async toLocation(city) {
      if (this.$route.query.cityType == 'user') {
        let req = { region: city };
        this.$store.commit('app/SET_LOADING', true);
        try {
          let res = await this.$Api(modifyPersonInfo, req);
          this.$store.commit('app/SET_LOADING', false);
          if (res && res.code == 200) {
            Toast('修改成功');
          } else {
            Toast(res.tip || '修改失败，请稍后再试');
          }
        } catch (error) {
          Toast('操作失败');
          this.$store.commit('app/SET_LOADING', false);
        }
      } else {
        setLocalItem('city', city);
      }
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.city_box {
  background-color: #ffffff;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
      text-align: right;
      font-size: 14px;
      color: #999999;
    }
  }
}

.van-search__content {
  background: #e5eff7;
}

.main /deep/ .van-field__control {
  color: #333333 !important;
  font-size: 13px;
}

/deep/ .van-search__content {
  padding-left: 20px;
  ::placeholder {
    color: #999999;
  }
}
.currentCity {
  padding: 10px;
  font-size: 12px;
}

.currentCity p {
  display: inline-block;
  margin: 0 6px 0 3px;
}

.hot-city {
  padding: 0 10px;
  font-size: 16px;
  .hot-city-title {
    padding-top: 5px;
  }
}

.hot-city-list {
  padding: 8px 0rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 6px;
  grid-column-gap: 6px;
  // border-bottom: solid 1px #343159;
}

.hot-city-list .city {
  height: 28px;
  font-size: 12px;
  background: #eef0f4;
  border-radius: 2px;
  line-height: 28px;
  text-align: center;
  color: #333333;
}

/* 遮挡层 */
.occlude {
  height: 5px;
  width: 100%;
  /* background-color:white; */
  position: relative;
  top: 1px;
}

/* 城市盒子 */
.city-list-box {
  height: calc(100vh - 206px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.letter {
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: #eef0f4;
  position: sticky;
  top: 0;
}

.city-list .city {
  padding: 2px 10px;
  font-size: 12px;
  line-height: 25px;
}

/* 城市字母 */
.letter-box {
  position: absolute;
  z-index: 100;
  top: 50%;
  margin-top: -140px;
  right: 0;
  min-height: 200px;
  width: 36px;
  text-align: center;
  font-size: 13px;
}

.letter-box p {
  height: 20px;
  width: 40px;
  line-height: 20px;
  font-size: 13px;
  text-align: center;
}

/* 字母气泡 */
.active-letter {
  height: 50px;
  width: 50px;
  background-color: #eef0f4;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: 50%;
  margin-top: -25px;
  right: 80px;
  line-height: 50px;
  text-align: center;
}

.active-letter span {
  font-size: 0.48rem;
  color: black;
}

/* 整页加载 */
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: black;

  img {
    width: 30px;
  }
}

/deep/ .van-loading--spinner {
  margin-top: unset;
}
</style>
